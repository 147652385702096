.footer {
  overflow: hidden;
  position: relative;
  @media (min-width: 768px) {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 368px;
  }
}

/* Footer Content */
.footer__content {
  color: #fff;
  display: grid;
  grid-column-gap: 60px;
  grid-row-gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto auto;
  margin-top: 100px;
  position: relative;
  z-index: 1;
  @media (max-width: 767px) {
    padding: 0 15px;
  }
  @media (min-width: 768px) {
    margin-top: 160px;
    grid-row-gap: 17px;
    grid-template-columns: 1fr 140px 140px 1fr;
    grid-template-rows: auto auto auto;
  }
  @media (max-width: 767px) {
    &::after {
      content: "";
      background-color: #6a1143;
      bottom: 0;
      height: 100px;
      width: 100%;
      position: absolute;
      left: 0;
      z-index: 0;
    }
  }
  a {
    color: #fff;
    text-decoration: none;
  }
}
.footer__item {
  margin: 0;
  @media (max-width: 767px) {
    display: block;
    // margin: 0 0 20px;
  }
}
.footer__item_1 {
  @media (min-width: 768px) {
    text-align: right;
  }
}
.footer__item_3 {
  grid-column-end: 3;
  grid-column-start: 1;
  position: relative;
  text-align: center;
  z-index: 1;
  @media (min-width: 768px) {
    grid-column-end: 5;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
  }
  a {
    text-decoration: underline;
  }
}
.footer__block {
  @media (min-width: 768px) {
    display: block;
  }
  text-decoration: underline;
}

/* Footer Art */
.footer__art {
  bottom: 0;
  position: absolute;
  @media (max-width: 767px) {
    bottom: 100px;
  }
}
.footer__art_1 {
  left: calc(~'(100% - 2706.994px) / 2');
}
.footer__art_2 {
  left: calc(~'(100% - 2484.7px) / 2');
}
.footer__art_3 {
  left: calc(~'(100% - 2304.7px) / 2');
}
.footer__art_4 {
  left: calc(~'(100% - 2239.7px) / 2');
}
