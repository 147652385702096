.portlet_news {
  z-index: 2;
  max-width: 820px;
	margin: 0 auto 20px;
  .footer & {
    flex: 1 0 auto;
  }
}

.portlet__header_news {
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
  @media (min-width: 768px) {
    margin-top: -22px;
    font-size: 130px;
  }
  .section-front-page & {
    color: #3e404d;
    text-transform: uppercase;
    @media (max-width: 767px) {
      padding: 20px 15px;
    }
    @media (min-width: 768px) {
      padding: 0 40px;
    }
  }
  a {
    color: #000;
    display: block;
    text-decoration: none;
    outline: none;
    .section-front-page & {
      color: #3e404d;
    }
    &:hover {
      outline: none;
      .section-front-page & {
        color: #fff;
      }
    }
  }
}
.portlet__content_news {
  position: relative;
  padding: 0 15px;
  @media (min-width: 768px) {
    padding: 0 50px;
  }
  .section-front-page & {
    @media (min-width: 768px) {
      height: 80%;
      display: flex;
      align-items: center;
    }
  }
}
.portlet__items {
  overflow: hidden;
  z-index: 1;
  position: relative;
  @media (min-width: 768px) {
    box-shadow: 1px 1px 13px rgba(0, 0, 0, 0.75);
    display: flex;
  }
  &:hover {
    @media (min-width: 768px) {
      box-shadow: 1px 1px 13px #fff;
    }
  }
}
.portlet__img {
  height: auto;
  max-width: 335px;
  width: 100%;
}
.portlet__item__content {
  display: block;
}
.portlet__item__content_title {
  font-size: 30px;
  font-weight: bold;
  line-height: 30px;
  margin-bottom: 10px;
}
.portlet__item__content_date {
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
}
.portlet__item__content_description {
  font-size: 18px;
  margin-bottom: 15px;
  line-height: 22px;
}


.carousel-item {
  width: 100%;
  padding: 15px;
  background-color: #fff;
  color: #000;
  box-sizing: border-box;
  @media (max-width: 767px) {
    display: block;
    margin-bottom: 20px;
  }
  @media (min-width: 768px) {
    flex-shrink: 0;
    transform: scale(1);
    transition: transform 1600ms;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 28px;
  }
  &:active,
  &:focus,
  &:hover {
    color: #000;
    text-decoration: none;
    .btn {
      background-color: #230b42;
      color: #fff;
    }
  }
  .section-front-page & {
    text-decoration: none;
  }
}





/* Slider Pagger arrow event */
.slide-radio {
  display: none;
}
.control {
  @media (max-width: 767px) {
    display: none;
  }
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50px;
  .section-front-page & {
    top: auto;
    bottom: auto;
  }
}
.control label {
  z-index: 0;
  display: none;
  cursor: pointer;
  height: 100%;
  transition: .1s ease;
  &:hover {
    .arrow {
      box-shadow: inset 2px -2px 0 2px #fff;
    }
  }
  &:active {
    .arrow {
      box-shadow: inset 2px -2px 0 2px #fff;
    }
  }
}
.arrow {
  width: 30px;
  height: 30px;
	display: block;
  box-shadow: inset 2px -2px 0 2px #adb2b1;
  transform: rotate(45deg);
  position: absolute;
  top: calc(~'50% - 15px');
  left: 0;
}
.arrow-next {
  transform: rotate(225deg);
  left: auto;
  right: 0;
}

.next {
  right: 0;
}
.previous {
  left: 0;
}



.slide-radio1:checked ~ .next .numb2,
.slide-radio2:checked ~ .next .numb3,
.slide-radio3:checked ~ .next .numb1,
.slide-radio2:checked ~ .previous .numb1,
.slide-radio3:checked ~ .previous .numb2,
.slide-radio1:checked ~ .previous .numb3 {
	display: block;
	z-index: 1
}

/* Slider slide effect */
.slide-radio1:checked ~ .portlet__items .slider {
	transform: translateX(0%);
}
.slide-radio2:checked ~ .portlet__items .slider {
	transform: translateX(-100%);
}
.slide-radio3:checked ~ .portlet__items .slider {
	transform: translateX(-200%);
}


