.header {
  width: 100%;
  z-index: 3;
  margin: 8px 0 18px;
  @media (min-width: 768px) {
    margin: 18px 0;
  }
}
.header__visual {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  min-height: 48px;
  margin-bottom: 40px;
  @media (min-width: 768px) and (max-height: 768px) {
    margin-bottom: 18px;
  }
  @media (max-width: 767px) and (orientation: landscape) {
    margin-bottom: 0;
  }
}
