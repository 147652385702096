/* Grid */
.container {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  .plone-toolbar-expanded & {
    width: calc(~'100% - 120px');
  }
  .plone-toolbar-default & {
    width: calc(~'100% - 60px');
  }
  @media (min-width: 768px) {
    max-width: 720px;
  }
}
.container_md {
  @media (min-width: 992px) {
    max-width: 960px;
  }
}
.container_lg {
  @media (min-width: 992px) {
    max-width: 960px;
  }
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
}

/* /[>// BOOTSTRAP GRID //<]/ */

/* /[>// This is exactly BOOTSTRAP GRID, replace it for your system */



/* // Container widths */
/* .container { */
  /* .container-fixed(); */

  /* @media (min-width: @plone-screen-sm-min) { */
    /* width: @plone-container-sm; */
  /* } */
  /* @media (min-width: @plone-screen-md-min) { */
    /* width: @plone-container-md; */
  /* } */
  /* @media (min-width: @plone-screen-lg-min) { */
    /* width: @plone-container-lg; */
  /* } */
/* } */


/* // Fluid container */
/* .container-fluid { */
  /* .container-fixed(); */
/* } */


/* // Row */
/* .row { */
  /* .clearfix(); */
  /* .make-row(); */
/* } */


/* // Columns */
/* .make-grid-columns(); */


/* // Extra small grid */
/* .make-grid(xs); */


/* // Small grid */
/* @media (min-width: @plone-screen-sm-min) { */
  /* .make-grid(sm); */
/* } */


/* // Medium grid */
/* @media (min-width: @plone-screen-md-min) { */
  /* .make-grid(md); */
/* } */


/* // Large grid */
/* @media (min-width: @plone-screen-lg-min) { */
  /* .make-grid(lg); */
/* } */


/* // toolbar needs to match grid  */
/* #edit-zone.plone-toolbar-top .plone-toolbar-container { */
  /* .container-fixed();  */
  /* @media (min-width: @plone-screen-sm-min) { */
    /* width: @plone-container-sm; */
  /* } */
  /* @media (min-width: @plone-screen-md-min) { */
    /* width: @plone-container-md; */
  /* } */
  /* @media (min-width: @plone-screen-lg-min) { */
    /* width: @plone-container-lg; */
  /* } */
  /* margin-top: 0; */
  /* margin-bottom: 0; */
  /* height: 50px; */
/* } */
/* #edit-zone.plone-toolbar-top.compressed .plone-toolbar-container {width: auto;} */
