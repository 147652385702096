//*// import some vars for the toolbar, we use a local copy here:
//@import url("https://raw.githubusercontent.com/plone/Products.CMFPlone/master/Products/CMFPlone/static/patterns/toolbar/src/css/variables.less");
@import "plone.toolbar.vars.less";
@primary-color: #0c131e;


@barceloneta_path: "../../barceloneta/less";
@import "@{barceloneta_path}/header.plone.less";
@import "@{barceloneta_path}/grid.plone.less";
@import "@{barceloneta_path}/footer.plone.less";
@import "@{barceloneta_path}/sitenav.plone.less";
@import "@{barceloneta_path}/portlets.plone.less";

/* scaffolding */
html {
  font-size: 16px;
  line-height: 20px;
  height: 100%;
  width: 100%;
  @media (min-width: 768px) {
    font-size: 16px;
    line-height: 22px;
  }
}
body {
  font-family: 'Lato', sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  width: 100%;
  &.plone-toolbar-expanded {
    width: calc(~'100% - 120px');
  }
  &.plone-toolbar-default {
    width: calc(~'100% - 60px');
  }
}
h1 {
  font-size: 4rem;
  line-height: 4rem;
  margin: 0 0 40px;
}
h2 {
  margin: 0 0 30px;
}
p {
  margin: 0 0 40px;
}
.glyphicon {
  display: none;
}
.btn {
  display: inline-block;
  padding: 7px 25px 8px;
  line-height: 1;
}
.btn_news {
  font-size: 12px;
  color: #1b0e2c;
  border: 1px solid #1b0e2c;
  text-transform: uppercase;
}
.btn_cases {
  .btn_news;
  font-weight: bold;
}
.text_center {
  text-align: center;
}
.text_sm {
  font-size: 14px;
  line-height: 20px;
}
.text_lg {
  font-size: 18px;
  line-height: 25px;
}
.grid {
  display: flex;
}
.grid_horizontal {
  flex-wrap: wrap;
}
.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.cssgrid {
  @media (min-width: 768px) {
    display: grid;
  }
}
.cssgrid_cases {
  grid-gap: 30px;
  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1025px) {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Header */
.header {
  position: fixed;
  @media (min-device-width : 768px) and (max-device-width : 1024px) {
    position: static;
  }
}

#portaltab-request-a-demo {
  background-color: #8C154C;
  &.selected {
    background-color: #000;
  }
  a{
    color: #fff;
  }
}

/* Section */
.section__title {
  font-size: 32px;
  line-height: 1;
  margin-bottom: 23px;
  @media (min-width: 768px) {
    font-size: 64px;
  }
  @media (min-width: 1025px) {
    font-size: 100px;
  }
}
.section__sub-title {
  font-size: 2.556rem;
  font-weight: bold;
  margin: 0 0 40px;
}
.section__description {
  font-size: 16px;
  line-height: 20px;
  @media (min-width: 768px) {
    font-size: 24px;
    margin-bottom: 50px;
    line-height: 30px;
  }
}
.section__sub-description {
  font-size: 1.333rem;
  line-height: 2rem;
  margin-bottom: 1.556rem;
}

.section_center {
  text-align: center;
}
.section_header {
  min-height: 670px;
  position: relative;
  margin-bottom: -5px;
}
.section_height {
  min-height: 100%;
}
.section_block {
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  /* justify-content: center; */
  position: relative;
  z-index: 3;
  /* background-color: #040d1d; */
  background-color: @primary-color;
  background: linear-gradient(to bottom, @primary-color 0%,#181632 45%,#1b0e2c 60%,#1c0b2a 69%,#1e0c2b 77%,#1f0b2a 88%,#19031e 100%);
  padding: 20px 0;
  @media (min-width: 768px) {
    padding: 80px;
  }
  @media (min-width: 1025px) {
    padding: 80px 80px 140px;
  }
}
.section_block_content-space-between {
  justify-content: space-between;
}
.section_block_content-center {
  justify-content: center;
}
.subsection_space-between {
  @media (min-width: 768px) {
    margin-bottom: 100px;
  }
}
.section_even {
  background: #fff;
  color: #000;
}

/* Section Achievements */
.achievements {
  overflow: hidden;
  min-height: 100%;
  @media (max-width: 1024px) {
    display: block;
    padding-bottom: 260px;
    &:before {
      content: "Expand Public Procurement. Limits with the System.";
      display: block;
      font-size: 45px;
      line-height: 1;
      text-align: center;
      margin: 0 5% 60px;
    }
  }
  @media (max-width: 767px) {
    padding: 20px 15px;
    padding-bottom: 200px;
    min-height: 100px;
    &:before {
      font-size: 32px;
      line-height: 1;
      margin-bottom: 23px;
    }
  }
  @media (min-width: 768px) {
    min-height: 80%;
  }
  @media (min-width: 1024px) {
    padding-top: 100px;
    min-height: 100%
  }
}
.achievements__background {
  position: absolute;
  bottom: 0;
  left: calc(~'(100% - 2527.48px) / 2');
}
.achievements__title {
  color: #1e0a2a;
  font-size: 60px;
  font-weight: 400;
  margin: 0 0 60px;
  transform: rotate(15deg);
  span {
    display: block;
  }
}
.achievements__infographic {
  z-index: 1;
  max-width: 1164px;
  font-size: 16px;
  line-height: 1;
  position: relative;
}
.achievements__background_1 {
  animation-duration: 14s;
  animation-name: slidein1;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes slidein1 {
  from {
    bottom: 0;
    margin-left: 0;
  }
  50% {
    margin-left: 30px;
  }
  to {
    bottom: -30px;
    margin-left: -30px;
  }
}
.achievements__background_2 {
  bottom: -1px;
  animation-duration: 15s;
  animation-name: slidein2;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}
@keyframes slidein2 {
  from {
    margin-left: 0;
  }
  50% {
    margin-left: -40px;
  }
  to {
    margin-left: 40px;
  }
}

.achievements__background_3 {
  left: calc(~'(100% - 1673.874px) / 2');
}

/* Section OCDS */
.ocds {
  padding-top: 35px;
  padding-bottom: 55px;
  background: linear-gradient(to bottom, #170033 0%,#181632 45%,#1b0e2c 60%,#1c0b2a 69%,#1e0c2b 77%,#1f0b2a 88%,#19031e 100%);
}
.ocds_bg {
  background-image: url('../images/bg_ocds.png');
  background-position: 0 0;
  background-repeat: no-repeat;
  @media (min-width: 1024px) {
    display: flex;
  }
}
.ocds__title {
  font-size: 280px;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 200px;
  }
  @media (min-width: 1025px) {
    text-align: left;
    font-size: 280px;
    letter-spacing: -12px;
    margin-top: -37px;
    padding-right: 45px;
  }
}
.ocds__description {
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  @media (min-width: 1025px) {
    text-align: left;
  padding-top: 125px;
}
}
.ocds__sub-title {
  font-size: 90px;
  font-weight: 700;
  line-height: 99.93px;
}
.ocds__sub-description {
  font-size: 31px;
  font-weight: 400;
  line-height: 35.5px;
}
.reference {
  margin-left: -36px;
  margin-right: -36px;
  text-align: left;
  @media (min-width: 1024px) {
    display: flex;
  }
}
.reference__item {
  border: 1px solid #fff;
  width: 50%;
  margin: 0 auto 23px;
  display: flex;
  align-items: center;
  padding: 20px 20px 21px 50px;
  font-size: 14px;
  line-height: 22px;
  position: relative;
  min-height: 66px;
  @media (min-width: 1024px) {
  width: 33%;
  margin: 0 36px;
  }
  &:after {
    align-items: center;
    background-image: linear-gradient(to top, #1d0a2a 0%, #15243c 100%);
    border-radius: 50%;
    border: 1px solid #fff;
    content: "";
    display: flex;
    font-size: 60px;
    font-weight: bold;
    height: 72px;
    justify-content: center;
    left: -37px;
    position: absolute;
    top: calc(~'50% - 38px');
    width: 72px;
  }
  &:nth-child(1):after {
    content: "1";
  }
  &:nth-child(2):after {
    content: "2";
  }
  &:nth-child(3):after {
    content: "3";
  }
}
.btn_ocds {
  background-color: #8b154c;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 10px 65px 12px;
  text-decoration: none;
  text-transform: uppercase;
  @media (min-width: 768px) {
    margin-top: 55px;
  }
  &:hover {
    background-color: #fff;
    color: #8b154c;
  }
}

/* Section Cases */
.cases__item {
  align-items: flex-start;
  border-radius: 6px;
  color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  text-decoration: none;
  &:hover {
    background-color: #f5f5f5;
    .btn {
      color: #fff;
      background-color: #1b0e2c;
    }
  }
}
.cases__item__logo {
  display: block;
  margin: 0 auto 25px;
  max-width: 225px;
}
.cases__title {
  font-size: 32px;
  font-weight: 400;
  line-height: 1;
  color: #122e43;
  @media (max-width: 767px) {
    margin-bottom: 23px;
  }
  @media (min-width: 768px) {
    font-size: 72px;
  }
}
.cases__item__content {
  flex: 1 0 auto;
}
.cases__item__title {
  color: #000506;
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  margin: 0 0 24px;
  display: block;
}
.cases__item__description {
  margin: 0 0 20px;
  display: block;
}
.list {
  margin: 0 0 30px;
}
.list_cases {
  padding: 0 0 0 14px;
  li {
    margin: 0 0 6px;
  }
}

/* Parallax */
.art {
  height: 100%;
  width: 100%;
  /* @media (min-width: 768px) { */
    display: flex;
    // align-items: flex-end;
    overflow: hidden;
    position: absolute;
  /* } */
  /* @media (min-device-width : 768px) and (max-device-width : 1024px) { */
    /* display: none; */
  /* } */
}
.site__logo {
  margin-bottom: 20px;
  text-align: center;
  padding: 0 15px;
  img {
    max-width: 517px;
    width: 100%;
    height: auto;
    margin-bottom: 10px;
    @media (min-width: 768px) and (max-height: 768px) {
      max-width: 425px;
    }
    @media (max-width: 767px) {
      max-width: 240px;
      margin-bottom: 0;
    }
  }
  p {
  }
}
.site__description {
  @media (max-width: 767px) {
    margin: 0 auto 20px;
  }
  @media (min-width: 768px) {
    width: 365px;
  }
  @media (min-width: 355px) {
    width: 325px;
    margin-left: auto;
    margin-right: auto;
  }
}

.art_parallax {
  position: fixed;
  transition: ease-out .05s;
  @media (min-device-width : 768px) and (max-device-width : 1024px) {
    position: absolute;
  }
}
.art_mobile {
  @media (max-width: 1024px) {
    background-image: url('../images/bg_home.png');
    background-position: 60% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
    bottom: 0;
  }
  @media (max-width: 767px) {
    background-attachment: fixed;
    background-size: auto 260px;
  }
  @media (max-width: 767px) and (orientation: landscape) {
    background-position: 50% 100%;
    background-size: auto 140px;
  }
}
.art_tree:after {
  background-image: url('../images/tree-dark.png');
  background-position: calc(~'50% + 185px') calc(~'100% - 90px');
  background-repeat: no-repeat;
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  @media (min-width: 768px) and (max-height: 900px) {
    background-position: calc(~'50% + 150px') calc(~'100% - 106px');
    background-size: auto 102px;
  }
  @media (min-width: 768px) and (max-height: 768px) {
    background-position: calc(~'50% + 120px') calc(~'100% - 71px');
    background-size: auto 73px;
  }
}
.art_tree_opacity:after {
  background-position: calc(~'50% - 440px') calc(~'100% - 235px');
  opacity: 0.75;
  @media (min-width: 768px) and (max-height: 900px) {
    background-position: calc(~'50% - 335px') calc(~'100% - 190px');
  }
  @media (min-width: 768px) and (max-height: 768px) {
    background-position: calc(~'50% - 240px') calc(~'100% - 130px');
  }
}
.art_birds_up {
  background-image: url('../images/birds_up.png');
  background-position: 97% 40%;
  background-repeat: no-repeat;
  @media (min-width: 768px) and (max-height: 768px) {
    background-size: auto 110px;
  }
}
.art_birds_forward {
  background-image: url('../images/birds_forward.png');
  background-position: 97% 40%;
  background-repeat: no-repeat;
  @media (min-width: 768px) and (max-height: 768px) {
    background-size: auto 110px;
  }
}
.art_birds_down {
  background-image: url('../images/birds_down.png');
  background-position: 97% 40%;
  background-repeat: no-repeat;
  @media (min-width: 768px) and (max-height: 768px) {
    background-size: auto 110px;
  }
}
.art__block {
  @media (min-width: 768px) {
    display: block;
  }
}
.art__img {
  position: absolute;
  bottom: 0;
}
.art__img_1 {
  left: calc(~'(100% - 2043.6px) / 2');
  z-index: 3;
  /* @media (min-width: 768px) and (max-height: 900px) { */
    /* height: 141px; */
  /* } */
  /* @media (min-width: 768px) and (max-height: 768px) { */
    /* height: 102px; */
  /* } */
  @media (max-width: 1024px) {
    height: 101px;
  }
  @media (max-width: 767px) and (orientation: landscape) {
    height: 90px;
  }
}
.art__img_2 {
  left: calc(~'(100% - 2282.7px) / 2');
  @media (min-width: 768px) and (max-height: 900px) {
    height: 227px;
  }
  @media (min-width: 768px) and (max-height: 768px) {
    height: 164px;
  }
}
.art__img_3 {
  left: calc(~'(100% - 2148.7px) / 2');
  @media (min-width: 768px) and (max-height: 900px) {
    height: 305px;
  }
  @media (min-width: 768px) and (max-height: 768px) {
    height: 220px;
  }
}
.art__img_4 {
  left: calc(~'(100% - 2019.993px) / 2');
  @media (min-width: 768px) and (max-height: 900px) {
    height: 333px;
  }
  @media (min-width: 768px) and (max-height: 768px) {
    height: 239px;
  }
}
.art__img_5 {
  left: calc(~'(100% - 2524.7px) / 2');
  z-index: 1;
  @media (min-width: 768px) and (max-height: 900px) {
    height: 392px;
  }
  @media (min-width: 768px) and (max-height: 768px) {
    height: 289px;
  }
}
.art__img_6 {
  left: calc(~'(100% - 2977.865px) / 2');
  @media (min-width: 768px) and (max-height: 900px) {
    height: 500px;
  }
  @media (min-width: 768px) and (max-height: 768px) {
    height: 380px;
  }
}
.art__img_sun {
  background-color: #f6aa16;
  border-radius: 50%;
  bottom: 265px;
  box-shadow: 0 0 160px #f6aa16;
  height: 116px;
  left: calc(~'50% + 215px');
  width: 116px;
  @media (min-width: 768px) and (max-height: 900px) {
    bottom: 200px;
    left: calc(~'50% + 160px');
    box-shadow: 0 0 150px #f6aa16;
    height: 81px;
    width: 81px;
  }
  @media (min-width: 768px) and (max-height: 768px) {
    bottom: 150px;
    left: calc(~'50% + 150px');
    box-shadow: 0 0 80px #f6aa16;
    height: 58px;
    width: 58px;
  }
}
.btn__arrow_down {
  align-content: center;
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  z-index: 3;
    height: 40px;
    width: 40px;
  @media (min-width: 1025px) {
    border: 2px solid #fff;
    bottom: 25px;
    height: 60px;
    left: 50%;
    margin-left: -30px;
    opacity: 0.4;
    position: absolute;
    width: 60px;
  }
  &:hover,
  &:focus {
    @media (min-width: 1025px) {
      opacity: 1;
      outline: none;
    }
  }
}
.icon__arrow_down {
  border-color: #253f52;
  border-style: solid;
  border-width: 0 2px 2px 0;
  display: inline-block;
  margin-bottom: 10px;
  padding: 10px;
  transform: rotate(45deg);
  @media (min-width: 1025px) {
    border-color: #fff;
    margin-bottom: 9px;
    padding: 8px;
  }
}

/* Section About */
.about__icon {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 7px;
  min-height: 53px;
}
.about__title {
  line-height: 16px;
}
.about__item {
  @media (max-width: 767px) {
    max-width: 100%;
    padding: 0 20px 20px;
  }
}

/* Section how-it-work*/
.section_block_how-it-work {
  padding: 0 0 20px;
  @media (min-width: 768px) {
    padding: 0 0 80px;
  }
}
.section_block_how-it-work__header {
  background-color: #fff;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 15px 0;
  @media (min-width: 768px) {
    padding: 80px 80px 0;
  }
}
.section_block_how-it-work__content {
  padding: 20px 15px;
  @media (min-width: 768px) {
    padding: 80px 80px 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
}
.how-it-work__header {
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }
}
.how-it-work__title {
  font-size: 32px;
  line-height: 1;
  color: #071732;
  margin-bottom: 23px;
  @media (min-width: 768px) {
    width: 55%;
    padding-right: 40px;
    font-size: 90px;
    line-height: 100px;
    margin-bottom: 40px;
  }
  @media (min-width: 1024px) {
    font-size: 100px;
  }
}
.how-it-work__description {
  color: #071732;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  @media (max-width: 767px) {
    margin-bottom: 23px;
  }
  @media (min-width: 768px) {
    width: 45%;
  }
}
.schema_how-to-work-op {
  font-size: 23px;
  max-width: 970px;
  width: 100%;
  height: auto;
  @media (min-width: 768px) {
    min-width: 608px;
  }
}

/* Section statistics */
.statistics {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  margin-bottom: 40px;
}
.statistics__item {
  width: 25%;
  padding: 0 10px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.statistics__value {
  font-size: 2.889rem;
  line-height: 2.889rem;
  font-weight: bold;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  height: 210px;
  min-width: 210px;
  margin-bottom: 20px;
  &:after {
    content: "";
    height: 186px;
    width: 186px;
    border: 12px solid #aab4bb;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
  }
}
.statistics__value__visual {
  display: inline-block;
  padding: 5px 0;
  margin-right: 40px;
  position: relative;
  &:after {
    content: "";
    width: 186px;
    background-color: #122e43;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
}
.statistics__value__text {
  position: relative;
  z-index: 2;
}
.statistics__description {
  font-size: 1.333rem;
  width: 85%;
  align-self: flex-end;
}





.navigation_page {
  position: fixed;
  right: 11px;
  top: calc(~'50% - 231px/2');
  z-index: 4;
  @media (max-width: 1024px) {
    display: none;
  }
}
.toc {
  list-style: none;
  margin: 0;
  padding: 0;
}
.toc__item {
  display: block;
  margin: 0 0 11px;
}
.toc__item__link {
  border: 5px solid #dbdbdb;
  background-color: #abaeae;
  border-radius: 50%;
  display: block;
  height: 12px;
  width: 12px;
  text-indent: -9999px;
  &:focus,
  &:active,
  &:hover {
    outline: none;
    background-color: #c60076;
  }
  &.current {
    background-color: #c60076;
  }
}

/* Footer */
.footer {
  padding: 0;
}

.hidden_mobile,
.navigation_page,
#section-ocds-support {
  @media (max-width: 767px) {
    display: none;
  }
}
.hidden_desktop {
  @media (min-width: 1025px) {
    display: none;
  }
}
.hidden_tablet {
  @media (min-device-width : 768px) and (max-device-width : 1024px) {
    display: none;
  }
}
.hidden {
  display: none;
}

// Soclink
.list--soclink {
  align-items: center;
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 10px 0;
  padding: 0;
  @media (min-width: 768px) {
    justify-content: flex-end;
    margin: 0;
  }
  li {
    margin: 0 10px;
    @media (min-width: 768px) {
      margin-right: 20px;
    }
  }
  svg {
    display: block;
  }
}

@isPlone:false;
@isPlone: false;