#portaltab-request-a-demo {
  background-color: #8C154C;
  &.selected {
  background-color: #000;
  }
  a {
    color: #fff;
  }
}
.plone-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: right;
  li {
    margin-bottom: 3px;
    @media (min-width: 769px) {
      display: inline-block;
      margin-bottom: 0;
    }
  }
  a {
    border-color: transparent;
    border-style: solid;
    border-width: 1px;
    color: #000;
    display: block;
    font-size: 14px;
    line-height: 16px;
    padding: 4px 12px 5px;
    text-decoration: none;
    text-transform: uppercase;
    transition: ease-out .065s;
    @media (max-width: 768px) {
      .active & {
        color: #fff;
        text-align: center;
        font-size: 16px;
        padding: 7px 12px;
      }
    }
    &:hover {
      border-color: #000;
      background-color: #000;
      color: #fff;
    }
  }
  .selected a {
    border-color: #000;
    @media (max-width: 768px) {
      .active & {
        border-color: #fff;
        font-weight: bold;
      }
    }
  }
}

#portal-globalnav-collapse {
  @media (max-width: 768px) {
    display: none;
  }
}
.plone-navbar-toggle {
  display: none;
  background: transparent;
  border: 0;
  height: 48px;
  margin: 0;
  padding: 0;
  position: relative;
  transform: rotate(0);
  transition: .5s ease-in-out;
  width: 48px;
  @media (max-width: 768px) {
    display: inline-block;
  }
  &:focus {
    outline: 0;
  }
  &:hover {
    cursor: pointer;
  }
  .icon-bar {
    background-color: #253f52;
    display: block;
    height: 3px;
    left: 9px;
    opacity: 1;
    position: absolute;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    width: 30px;
  }
  .icon-bar:nth-child(1) {
    top: 14px;
  }
  .icon-bar:nth-child(2) {
    top: 20px;
  }
  .icon-bar:nth-child(3) {
    top: 26px;
  }
}
.header.active {
  @media (max-width: 768px) {
    margin: 0;
    padding: 8px 0 18px;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #0c131e;
    z-index: 5;
    .icon-bar {
      background-color: #fff;
    }
    .icon-bar:nth-child(1) {
      top: 20px;
      transform: rotate(135deg);
    }
    .icon-bar:nth-child(2) {
      left: -60px;
      opacity: 0;
    }
    .icon-bar:nth-child(3) {
      top: 20px;
      transform: rotate(-135deg);
    }
    #portal-logo,
    .social-links {
      display: none;
    }
    .navigation_global {
      width: 100%;
    }
    .plone-navbar-header {
      text-align: right;
    }
    #portal-globalnav-collapse {
      display: block;
    }
  }
}

// Footer Navigation
.list--navigation {
  list-style: none;
  padding: 0;
  &.list {
    margin: 0;
    position: relative;
    z-index: 1;
  }
  a {
    text-decoration: underline;
  }
}

/* Social links */
.social-links {
  margin: 0;
  padding: 0;
  list-style: none;
}
.social-links__link {
  display: inline-block;
  margin-right: 18px;
  a {
    outline: none;
    display: block;
    &:hover {
      .svg__twitter {
        fill: #a8216f;
        stroke: #a8216f;
      }
      .svg__facebook_1 {
        fill: #a8216f;
        stroke: #a8216f;
      }
      .svg__facebook_2 {
        fill: #fff;
      }
    }
  }
}
